.contact-wrapper {
  width           : 100%;
  background-color: var(--secondary-dark-color);
  text-align      : center;
  padding         : 30px 0;
}

.contact-container {
  display        : flex;
  justify-content: space-between;
  width          : 80vw;
  margin         : auto;
  max-width      : 1000px;
  text-align     : left;
  color          : var(--primary-light-color);
}

.contact-left h2 {
  font-size: 18px;
}

.contact-right a {
  text-decoration: none;
  color          : var(--primary-light-color);
  margin-left    : 20px;
  font-size      : 14px;
  font-weight    : bold;
}

@media only screen and (max-width: 768px) {
  .contact-container {
    width: 90vw;
  }
}

@media only screen and (max-width: 480px) {
  .contact-container {
    display: block;
  }

  .contact-left {
    margin-bottom: 15px;
  }

  .contact-right a {
    margin : 10px 0;
    display: block;
  }
}
