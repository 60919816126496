.our-services-wrapper {
  width           : 100%;
  background-color: var(--primary-light-color);
  text-align      : center;
  padding         : 60px 0;
}

.our-services-container {
  display        : flex;
  justify-content: space-between;
  width          : 80vw;
  margin         : auto;
  max-width      : 1000px;
  text-align     : left;
}

.our-services-left {
  padding-right: 60px;
}

.our-services-container h1 {
  font-size: 24px;
}

.our-services-pretext {
  color        : var(--secondary-dark-color);
  font-size    : 10px;
  margin-bottom: 15px;
  font-weight  : bold;
}

.our-services-subtext {
  font-size: 12px;
  margin: 15px 0;
}

.our-services-icon-tags {
  padding        : 10px 0 20px 0;
  font-size      : 12px;
  display        : flex;
  justify-content: flex-start;
  flex-wrap      : wrap;
}

.our-services-buttons .button-primary {
  margin-right: 15px;
}

.our-services-hiring-link {
  margin-top: 20px;
}

.our-services-hiring-link a {
  text-decoration: none;
  color          : var(--secondary-dark-color);
  font-size      : 12px;
}

.our-services-image-mobile {
  display      : none;
  margin-bottom: 20px;
  text-align   : center;
}

@media only screen and (max-width: 768px) {
  .our-services-wrapper {
    padding: 30px 0;
  }

  .our-services-container {
    display: block;
    width  : 90vw;
  }

  .our-services-right {
    display: none;
  }

  .our-services-left {
    padding-right: 0;
  }

  .our-services-image-mobile {
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  .our-services-buttons button {
    width: 100%;
  }

  .our-services-buttons .button-primary {
    margin-bottom: 10px;
  }
}