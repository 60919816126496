.header-wrapper {
  width           : 100%;
  background-color: var(--primary-dark-color);
  padding         : 20px 0;
  text-align      : center;
}

.header-container {
  display        : flex;
  justify-content: space-between;
  width          : 80vw;
  margin         : auto;
  max-width      : 1000px;
  align-items    : center;
}

.header-container a {
  color          : var(--primary-light-color);
  text-decoration: none;
  padding        : 0 20px 0 0;
  vertical-align : middle;
  font-weight    : bold;
}

.header-menu {
  display         : none;
  background-color: transparent;
  border          : 1px solid var(--primary-light-color);
  color           : var(--primary-light-color);
  border-radius   : 5px;
  padding         : 5px;
  align-items     : center;
  font-size       : 20px;
}

@media only screen and (max-width: 768px) {
  .header-container {
    width: 90vw;
  }

  .header-right {
    display: none;
  }

  .header-menu {
    display: inline-flex;
  }
}