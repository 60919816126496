.footer-wrapper {
  width           : 100%;
  background-color: var(--primary-dark-color);
  text-align      : center;
  padding         : 10px 0;
}

.footer-container {
  display        : flex;
  justify-content: space-between;
  width          : 80vw;
  margin         : auto;
  max-width      : 1000px;
  text-align     : left;
  color          : var(--primary-light-color);
  font-size      : 12px;
  align-items    : center;
}

.footer-left {
  font-weight: bold;
}

.footer-left a {
  display        : block;
  text-decoration: none;
  color          : var(--primary-light-color);
  padding-top    : 8px;
}

@media only screen and (max-width: 768px) {
  .footer-container {
    width: 90vw;
  }
}

@media only screen and (max-width: 480px) {
  .footer-container {
    display: block;
  }

  .footer-right {
    margin-top: 20px;
  }
}
